
import { postRequest } from '../../tools/httpRequest';
import FormSection from '../FormSection';
import AnswerSection from '../AnswersSection';
import { useEffect, useState } from 'react';
import { BQActions } from '../../tools/bq_enums';
import Checkbox from '@mui/material/Checkbox';
import SelectInput from '@mui/material/Select/SelectInput';
import { FormControl, InputLabel, Select } from '@mui/material';

export const QMS_SBS = ({ title }) => {
    const [answer, setAnswer] = useState();
    const [asking, setAsking] = useState(false);
    const [working, setWorking] = useState(false);
    const [queryData, setQueryData] = useState({});
    const [selectionSubmitted, setSelectionSubmitted] = useState(false);
    const [feedback, setFeedback] = useState({});

    useEffect(() => {
        if (queryData && Object.keys(queryData).length) {
            setFeedback(null)
            setAsking(queryData.query)
            postRequest({ action: BQActions.QMS_QUERY_SIDE_BY_SIDE, payload: queryData }).then(data => {
                setAnswer(data)
            }).finally(() => setAsking(false))
        }
    }, [queryData])

    useEffect(() => {
        if (selectionSubmitted && feedback) {
            setSelectionSubmitted(false)
        }
    }, [feedback])

    const saveSelection = () => {
        setWorking(true)
        if (feedback && Object.keys(queryData).length) {
            setWorking(true)
            postRequest({ action: BQActions.USER_FEEDBACK, payload: { mondayItemId: answer?.mondayItemId, feedback } })
                .then(data => {
                    setSelectionSubmitted(true)
                })
                .finally(() => setWorking(false))
        }
    }

    return <>
        <div className="header-section">
            <h1>{title}</h1>
        </div>
        <FormSection generateResponse={query => {
            setQueryData({ query, sourceBatch: 0 })
        }} />

        {!selectionSubmitted && answer && <div style={{ margin: '24px 0', fontWeight: 'bold' }}>Mark the best answer: {feedback && Object.keys(feedback).length && <button style={{ width: 128, marginLeft: 16 }} onClick={() => saveSelection()}>Submit selection</button>}</div>}

        {answer && <span>
            <div>Write down your preferred answer if you like:</div>
            <textarea
                id="feedback-text"
                className="form-control"
                onChange={(e) => setFeedback({
                    ...feedback,
                    text: e.target.value
                })}
                value={feedback?.text || ''}
            />
        </span>}

        <table>
            <tbody>
                <tr>
                    <td style={{ verticalAlign: 'top', width: '50%', minWidth: '50%' }}>
                        {answer && <table>
                            <tr>
                                <td>
                                    Quailty of this answer:&nbsp;&nbsp;
                                </td>
                                <td>
                                    <Select
                                        native
                                        value={feedback?.v1 || 'None'}
                                        onChange={(e) => {
                                            setFeedback({
                                                ...feedback,
                                                v1: e.target.value
                                            })
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="Bad">Bad</option>
                                        <option value="Good">Good</option>
                                    </Select>
                                </td>
                            </tr>
                        </table>
                        }
                        <br />
                        <br />
                        <AnswerSection asking={asking} working={working} data={answer?.v1} key="v1" />
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td style={{ verticalAlign: 'top', width: '50%', minWidth: '50%' }}>
                        {answer && <table>
                            <tr>
                                <td>
                                    Quailty of this answer:&nbsp;&nbsp;
                                </td>
                                <td>
                                    <Select
                                        native
                                        value={feedback?.v2 || 'None'}
                                        onChange={(e) => {
                                            setFeedback({
                                                ...feedback,
                                                v2: e.target.value
                                            })
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="Bad">Bad</option>
                                        <option value="Good">Good</option>
                                    </Select>
                                </td>
                            </tr>
                        </table>
                        }
                        <br />
                        <br />
                        <AnswerSection asking={asking} working={working} data={answer?.v2} key="v2" />
                    </td>
                </tr>
            </tbody>
        </table >
    </>
}
