import { useState } from 'react';

const FormSection = ({ placeholder, generateResponse }) => {
    const [newQuestion, setNewQuestion] = useState('');

    return (
        <div className="form-section">
            <textarea
                rows="3"
                className="form-control"
                placeholder={placeholder || "Ask someting, like - What are the cellular targets of ELF-EMF?"}
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        e.stopPropagation();
                        e.preventDefault();
                        generateResponse(newQuestion)
                    }
                }}
            ></textarea>
            <button className="btn" onClick={() => generateResponse(newQuestion)}>
                Send query
            </button>
        </div>
    )
}

export default FormSection;