import { useEffect, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import awsConfig from './aws-exports';
import { QMS } from './modules/QMS/qms_index';
import { CSVModule } from './modules/CSV/csv_index';
import { SideBar } from './modules/SideBar';
import { BQActions, BQCategories } from './tools/bq_enums';
import { getCookie, setCookie } from './tools/cookieManager';
import { QMS_SBS } from './modules/QMS/qms_sbs_index';

const origin = window.location.origin + "/"

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: origin,
    redirectSignOut: origin,
    responseType: "code",
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin']
  }
}

console.log(updatedAwsConfig)
Amplify.configure(updatedAwsConfig);

function App() {
  const selectedCategoryCookie = JSON.parse(getCookie('selectedCategory'))


  const [user, setUser] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(selectedCategoryCookie ? BQCategories[selectedCategoryCookie.name] : BQCategories.QMS_AI);

  const handleUser = (userData) => {
    setUser(userData)
  }


  const SelectedModule = () => {
    switch (selectedCategory) {
      case BQCategories.QMS_AI:
        return <QMS title={selectedCategory.title} />
      case BQCategories.QMS_AI_SIDE_BY_SIDE:
        return <QMS_SBS title={selectedCategory.title} />
      case BQCategories.CSV_MAGIC:
        return <CSVModule title={selectedCategory.title} />
      default:
        return (<div>Nothing selected</div>)
    }
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('event', event, data);
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          // getUser()
          //   .then(userData => handleUser(userData))
          //   .catch(e => console.error(e));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser()
  }, []);

  const getUser = () => Auth.currentAuthenticatedUser()
    .then(userData => handleUser(userData))
    .catch(() => { })

  return (
    <div>
      <SideBar user={user} selectedCategory={selectedCategory} onSelectedItemChanged={(selectedCategory) => {
        setSelectedCategory(selectedCategory)
        setCookie('selectedCategory', selectedCategory, 1)
      }} />
      {user ?
        <SelectedModule />
        :
        <div>Sign in to continue</div>
      }

    </div>
  );
}

export default App;