import { Auth } from 'aws-amplify';
import data from '../aws-exports';
import { BQActions } from './bq_enums';

const envName = data.oauth.domain.match(/playground(.*?)\./)[1]
const playgroundApiUrl = `https://api-playground${envName}.brainqtech.com/InvokeFunction`
let lambdaFunctionUrl = null

const fetchWithToken = async (method, payload) => {
    const session = await Auth.currentAuthenticatedUser()

    const token = session.signInUserSession.accessToken.jwtToken

    const response = await fetch(lambdaFunctionUrl || playgroundApiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        },
        body: JSON.stringify(payload),
    });
    return await response.json();
};

export const postRequest = async (payload) => await fetchWithToken('POST', payload)

export const getRequest = async (payload) => await fetchWithToken('GET', payload)

export const putRequest = async (payload) => await fetchWithToken('PUT', payload)

export const uploadFile = async (file, presignedUrlData) => {
    const { url, fields } = presignedUrlData
    const formData = new FormData();
    Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
    })
    formData.append('file', file);
    return await fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        body: formData,
    });
}

postRequest({ action: BQActions.GET_LAMBDA_FUNCTION_URL }).then(res => {
    lambdaFunctionUrl = res?.functionUrl
    console.log('lambdaFunctionUrl', lambdaFunctionUrl)
}).catch(e => console.error(e))
