import { useEffect, useState } from "react"
import { BQActions, BQCookies } from "../../tools/bq_enums"
import { postRequest, uploadFile } from "../../tools/httpRequest"
import FormSection from "../FormSection"
import AnswerSection from "../AnswersSection"
import { getCookie, setCookie } from "../../tools/cookieManager"
import { LinearProgress, Slider } from "@mui/material"

export const CSVModule = ({ title }) => {
    const csvFilesCookie = getCookie(BQCookies.CSV_FILES)
    const [filesToUpload, setFilesToUpload] = useState()
    const [availableFiles, setAvailableFiles] = useState(csvFilesCookie && JSON.parse(csvFilesCookie))
    const [asking, setIsAsking] = useState(false)
    const [answer, setAnswer] = useState()
    const [temprature, setTemprature] = useState(0)

    const uploadFiles = async () => {
        const fileUploader = document.getElementById('fileUploader')
        if (filesToUpload && filesToUpload.length) {
            const handledFiles = []
            for (let fileContent of filesToUpload) {
                console.log(fileContent.type)
                const s3SignedURLResponse = await postRequest({ action: BQActions.CSV_UPLOAD })

                console.log(s3SignedURLResponse)
                const { fileName, presignedUrlData } = s3SignedURLResponse
                const uploadFileResponse = await uploadFile(fileContent, presignedUrlData)
                console.log('uploadFileResponse', uploadFileResponse)
                handledFiles.push({
                    originalName: fileContent.name,
                    s3FileName: fileName
                })
            }
            setCookie(BQCookies.CSV_FILES, handledFiles, 1)
            setAvailableFiles([...availableFiles, ...handledFiles]);
            setIsAsking(false)
        }
        fileUploader.value = null
        setFilesToUpload()
    }

    const clearFiles = () => {
        setAvailableFiles([])
        setCookie(BQCookies.CSV_FILES, null, 1)
    }

    useEffect(() => {
        uploadFiles()
    }, [filesToUpload])

    return <>
        <div>
            <input
                id="fileUploader"
                type="file"
                accept=".csv"
                multiple
                style={{ display: 'none' }}

                onChange={e => {
                    setFilesToUpload([...e.target.files])
                }}
            />

            <div className="toolbar">
                {!!filesToUpload && <div>
                    <h3>Uploading the following files:</h3>
                    <br />
                    {filesToUpload?.map(file => <div>{file.name}</div>)}
                    <br />
                    <LinearProgress />
                    <br />

                </div>}

                {!!availableFiles?.length && <div>
                    <h3>Available Files:</h3>
                    <br />
                    {!!availableFiles?.length && availableFiles?.map((item, index) => <div>{index + 1}. {item.originalName}</div>)}
                    <br />
                </div>
                }
                {!filesToUpload && <button onClick={() => document.getElementById('fileUploader').click()}>
                    Select CSV files to upload
                </button>
                }
                <br /><br />
                {!!availableFiles?.length && <><button onClick={() => clearFiles(null)}>
                    Clear files
                </button>


                </>
                }
                <br />
                <br />
                Tamprature: {temprature}
                <Slider onChange={(e) => {
                    setTemprature(e.target.value / 100)
                }} />
            </div>

            <div className="header-section">
                <h1>{title}</h1>
            </div>

            <FormSection
                placeholder="Ask something, like how many rows are there in the files?"
                generateResponse={query => {
                    const filesToSend = availableFiles?.map(file => file.s3FileName.replace('.csv', ''))
                    setIsAsking(query)
                    postRequest({
                        action: BQActions.CSV_QUERY, payload: {
                            temprature,
                            files: filesToSend,
                            query
                        }
                    })
                        .then(data => {
                            setAnswer(data)
                            console.log(data)
                        })
                        .finally(() => {
                            setIsAsking(false)
                        })
                }} />


            <AnswerSection asking={asking} data={answer} style={{ opacity: asking ? 0.3 : 1 }} />

            {/* <button className="btn" onClick={() => postRequest({ action: BQActions.CSV_QUERY }).then(data => {
                console.log(data)
            })}>
                Send query
            </button> */}
        </div >
    </>
}
