import { CSVModule } from "../modules/CSV/csv_index"
import { QMS } from "../modules/QMS/qms_index"

export const BQCategories = {
    QMS_AI_SIDE_BY_SIDE: {
        name: 'QMS_AI_SIDE_BY_SIDE',
        title: 'QMS AI Side by Side'
    },
    QMS_AI: {
        name: 'QMS_AI',
        title: 'QMS AI'
    },
    CSV_MAGIC: {
        name: 'CSV_MAGIC',
        title: 'CSV Magic'
    },
}

export const BQActions = {
    GET_LAMBDA_FUNCTION_URL: 'GET_LAMBDA_FUNCTION_URL',
    QMS_QUERY: 'QMS_QUERY',
    QMS_QUERY_SIDE_BY_SIDE: 'QMS_QUERY_SIDE_BY_SIDE',
    USER_FEEDBACK: 'USER_FEEDBACK',
    CSV_UPLOAD: 'CSV_UPLOAD',
    CSV_QUERY: 'CSV_QUERY',
}

export const BQCookies = {
    CSV_FILES: 'CSV_FILES',
}