
import { postRequest } from '../../tools/httpRequest';
import FormSection from '../FormSection';
import AnswerSection from '../AnswersSection';
import { useEffect, useState } from 'react';
import { BQActions } from '../../tools/bq_enums';

export const QMS = ({ title }) => {
    const [answer, setAnswer] = useState();
    const [asking, setAsking] = useState(false);
    const [queryData, setQueryData] = useState({});

    useEffect(() => {
        if (queryData && Object.keys(queryData).length) {
            setAsking(queryData.query)
            postRequest({ action: BQActions.QMS_QUERY, payload: queryData }).then(data => {
                setAsking(false)
                setAnswer(data)
            })
        }
    }, [queryData])

    return <>
        <div className="header-section">
            <h1>{title}</h1>
        </div>
        <FormSection generateResponse={query => {
            setQueryData({ query, sourceBatch: 0 })
        }} />
        <table style={{ width: '100%', marginTop: '-24px', marginBottom: '24px' }}>
            <tr>
                <td style={{ minWidth: '128px' }}>{!!queryData.sourceBatch &&
                    <button onClick={() => setQueryData({ query: queryData?.query, sourceBatch: Math.max(queryData.sourceBatch - 1, 0) })}>&lt;&lt; Previous 5 items</button>
                }
                </td>
                <td style={{ width: '100%' }}></td>
                <td style={{ minWidth: '128px' }}><button onClick={() => setQueryData({ query: queryData?.query, sourceBatch: (queryData.sourceBatch || 0) + 1 })}> Next 5 items &gt;&gt;</button></td>
            </tr>
        </table>
        <AnswerSection asking={asking} data={answer} />
    </>
}
