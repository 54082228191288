import { useState } from "react"
import { BQCategories } from "../tools/bq_enums"
import { Auth } from "aws-amplify"

export const SideBar = ({ user, selectedCategory, onSelectedItemChanged }) => {
    const categories = Object.keys(BQCategories).map(key => {
        return {
            name: key,
            title: BQCategories[key].title
        }
    })

    const [selectedItem, setSelectedItem] = useState(categories.findIndex(item => item.name === selectedCategory.name) || 0)

    const setSelectedCategoryIndex = (index) => {
        setSelectedItem(index)
        onSelectedItemChanged?.(BQCategories[categories[index].name])
    }

    return (<div id="sideBar" className="side-bar">
        <div className="side-bar-header">BQ Playground</div>
        <div className="side-bar-category-container">
            {categories?.map((category, index) => <div
                key={`sideBarCategory_${index}`}
                className="side-bar_category"
                onClick={() => setSelectedCategoryIndex(index)}
            >
                <div className={selectedItem === index ? 'selected-category-indicator' : 'empty-category-indicator'}></div>
                {category?.title}
            </div>)}
        </div>
        <div className="side-bar-auth-section">
            {user && <div className="side-bar-user-info">{user.attributes?.email}</div>}
            {!!user ? <button onClick={() => Auth.signOut()}>Sign Out</button> : <button onClick={() => Auth.federatedSignIn()}>Sign In with Google</button>}
        </div>
    </div>)
}