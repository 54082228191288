import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Collapse, LinearProgress, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const encodeHTML = text => {
    if (!text) {
        return null
    }
    var textArea = document.createElement('textarea');
    textArea.innerText = text;
    return textArea.innerHTML || null
}

const AnswerSection = ({ data, asking, working }) => {
    const [selectedSource, setSelectedSource] = useState();
    const copyText = (text) => {
        navigator.clipboard.writeText(text);
    };

    const { answer, sources } = data || {}
    let parsedAnswer = answer ? (typeof answer === 'string' ? encodeHTML(answer?.replace(/Page no\.  (\d+)/ig, '[source no. $1]').trim()) : 'Error') : null

    while (parsedAnswer?.indexOf('\n ') > -1) {
        parsedAnswer = parsedAnswer.replace('\n ', '\n\n').trim()
    }

    const sourceMatches = parsedAnswer?.match(/\[source no\..*?(\d+)\]/ig)
    const sourceFixes = sourceMatches?.map(sourceMatch => {
        const number = parseInt(sourceMatch.match(/\[source no\..*?(\d+)\]/i)[1])
        return {
            from: sourceMatch,
            to: `<span title="${number}. ${sources[number - 1]?.file_name}" style="cursor: pointer; "><u><a href="${sources[number - 1]?.link}" target="_blank" style="color: #00AAFF">[Source no. ${number}]</a></u></span>`
        }
    })

    sourceFixes?.forEach(sourceFix => {
        parsedAnswer = parsedAnswer.replace(sourceFix.from, sourceFix.to)
    })

    if (data?.selectedSource) {
        parsedAnswer += `\n\n<b>Selected source:</b> ${sources?.find(item => data.selectedSource.endsWith(item.file_name))?.file_name || data.selectedSource}`
    }

    return (
        <div>
            {asking && <div className="asking">Asking: "{asking}"...<br /><br /><LinearProgress /><br /></div>}
            {working && <LinearProgress />}
            <div style={{ opacity: asking ? 0.3 : 1 }}>
                <div className="answer-container">
                    <b>{data?.query}</b>
                </div>
                <hr className="hr-line" style={{ margin: '20px 0' }} />

                <div className="answer-container answer-text" dangerouslySetInnerHTML={{
                    __html: parsedAnswer
                }}></div>

                {!!sources && <div>
                    <hr className="hr-line" />
                    Sources:
                    <hr className="hr-line" style={{ margin: '20px 0' }} />
                    <div className="answer-container">


                        {sources?.map((source, index) => {

                            return <>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <table >
                                            <tr>
                                                <td><Checkbox
                                                    onClick={(e) => e.stopPropagation()}
                                                    onChange={(e) => source.isSelected = e.target.checked} /></td>
                                                <td>
                                                    <div>
                                                        <a href={source?.link}
                                                            target="_blank"
                                                            style={{ color: '#00AAFF' }}
                                                            onClick={(e) => e.stopPropagation()}>
                                                            {index + 1}. {encodeHTML(source.file_name)}
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {source.text}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </>

                        })}


                    </div>
                    <hr className="hr-line" />
                </div >}
            </div>
        </div >
    )
}

export default AnswerSection;